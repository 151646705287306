import { AuctionDto, FINISHED_STATUS, PENDING_STATUS, STATUS_IN_CORSO } from 'src/app/core/models/auction-dto';
import { AuctionFilterDto } from 'src/app/core/models/auction-filter-dto';
import { createReducer, on } from '@ngrx/store';
import {
  ApplyAuctionsFilters,
  BuyNowSuccessList,
  DeleteOfferSuccessList,
  DistributeValuesListSuccess,
  GetAuctionPriceListSuccess,
  LoadAuctions,
  LoadAuctionsFailure,
  LoadAuctionsSuccess,
  MakeOfferSuccessList,
  ResetAuctionsFilters,
  SetAuctionsNextPage,
  SetAuctionsSort,
  SetCounterToPay,
  SetDraftFilter,
  TogglePreferenceListSuccess,
  LoadMakesFilter,
  LoadMakesFilterFailure,
  LoadMakesFilterSuccess,
} from './auction.actions';
import {MakeDto} from "../../core/models/make-dto";

export const KM_FILTER = [0, 300000];
export const YEAR_FILTER = [2010, new Date().getFullYear()];

export interface State {
  draftFilters: AuctionFilterDto;
  filters: AuctionFilterDto;
  filersMake: {
    makes: MakeDto[];
    fetching: boolean;
    error: boolean;
  };
  page: number;
  status: number[];
  statusDesc: string;
  sortBy: string; 
  sortOrder: 'asc' | 'desc';
  isFetching: boolean;
  toPay: number;
  auctions: AuctionDto[];
  auctionsFinished: AuctionDto[];
  canLoadMoreRecords: boolean;
}

export const initialState: State = {
  draftFilters: {},
  filters: {},
  filersMake: {
    makes: [],
    error: false,
    fetching: false
  },
  page: 1,
  status: PENDING_STATUS,
  statusDesc: 'pending',
  sortBy: 'astaFineDataora',
  sortOrder: 'asc',
  isFetching: false,
  toPay: null,
  auctions: [],
  auctionsFinished: [],
  canLoadMoreRecords: true
};

export const auctionReducer = createReducer(
  initialState,

  on(LoadAuctions, (state, action) => {
    let status = null;
    let sortOrder: 'asc'| 'desc' = 'asc';
    switch (action.status) {
      case 'pending':
      case 'favourite':
        status = PENDING_STATUS;
        break;
      case 'finished':
        status = FINISHED_STATUS;
        sortOrder = 'desc';
        break;
      default:
        break;
    }
    return {
      ...state,
      ...{
        status,
        isFetching: true,
        statusDesc: action.status,
        sortOrder: action.status === state.statusDesc ? state.sortOrder: sortOrder,
        auctionsFinished: action.status === state.statusDesc ? state.auctionsFinished : [],
        page: action.status === state.statusDesc ? state.page : initialState.page,
        filters: action.status === state.statusDesc ? state.filters: initialState.filters,
        draftFilters: action.status === state.statusDesc ? state.draftFilters: initialState.draftFilters
      }
    };
  }),
  on(LoadAuctionsSuccess, (state, action) => ({
      ...state,
      ...{
        auctions: state.statusDesc === 'finished' ? state.auctions: action.records,
        auctionsFinished: state.statusDesc === 'finished' ? action.records : state.auctionsFinished,
        isFetching: false,
        canLoadMoreRecords: action.canLoadMoreRecords
     //   page: initialState.page
      }
    })
  ),
  on(LoadAuctionsFailure, (state, action) => ({
      ...state,
      ...{
        auctions: [],
        auctionsFinished: [],
        isFetching: false,
    //   page: initialState.page
      }
    })
  ),
  on(SetCounterToPay, (state, action) => ({
      ...state,
      ...{
        toPay: action.count
      }
    })
  ),
  on(SetAuctionsNextPage, (state, action) => ({
      ...state,
      ...{
        page: state.page + 1,
      }
    })
  ),
  on(SetDraftFilter, (state, action) => ({
    ...state,
    ...{
      draftFilters: {
        ...state.draftFilters,
        ...action.filter
      }
    }
  })
  ),
  on(ApplyAuctionsFilters, (state, action) => ({
      ...state,
      ...{
        auctionsFinished: [],
        page: 1,
        filters: state.draftFilters
      }
    })
  ),
  on(ResetAuctionsFilters, (state, action) => ({
      ...state,
      ...{
        auctionsFinished: [],
        page: 1,
        filters: initialState.filters,
        draftFilters: initialState.filters
      }
    })
  ),
  on(SetAuctionsSort, (state, action) => ({
    ...state,
    ...{
      auctionsFinished: [],
      sortBy: action.sort.sortBy,
      sortOrder: action.sort.sortOrder
    }
  })),

  on(TogglePreferenceListSuccess, (state, action) => {
    return {
      ...state,
      ...{
        auctions: state.auctions.map(
          auction => {
            if(auction.id === action.auctionId) {
              const newAuction = new AuctionDto(auction);
              newAuction.isFavourite = !auction.isFavourite;
              return newAuction;
            } else {
              return auction;
            }
          }
        )
      }
    }
  }),

  on(MakeOfferSuccessList, (state, action) => ({
    ...state,
    ...{
      auctions: state.auctions.map(
        auction => {
          if(auction.id === action.auction.id) {
            return Object.assign(new AuctionDto(), {
              ...auction,
              ...{
                actualOffer: action.auction.actualOffer,
                isWinner: action.auction.isWinner,
                isFavourite: true,
                lastOffer: {
                  maxValue: action.auction.lastOffer.maxValue,
                  total: action.auction.lastOffer.maxValue
                }
              }
            });
          } else {
          return auction;
          }
        }
      )
    }
  })),

  on(DeleteOfferSuccessList, (state, action) => ({
    ...state,
    ...{
      auctions: state.auctions.map(
        auction => {
          if(auction.id === action.auction.id) {
            return Object.assign(new AuctionDto(), {
              ...auction,
              ...{
                actualOffer: action.auction.actualOffer,
                isWinner: action.auction.isWinner,
                lastOffer: {
                  maxValue: action.auction.lastOffer.maxValue,
                  total: action.auction.lastOffer.maxValue
                }
              }
            });
          } else {
          return auction;
          }
        }
      )
    }
  })),

  on(BuyNowSuccessList, (state, action) => ({
    ...state,
    ...{
      auctions: state.auctions.filter(auction => auction.id !== action.auction.id)
    }
  })),

  on(DistributeValuesListSuccess, (state, action) => ({
    ...state,
    ...{
      auctions: state.auctions.map(
        auction => {
          if(auction.id === action.auctionId) {
            return Object.assign(new AuctionDto(), {
              ...auction,
              ...{
                lastOffer: {
                  ...auction.lastOffer,
                  ...{
                    vehicles: action.distribution
                  }
                }
              }
            });
          } else {
          return auction;
          }
        }
      )
    }
  })),

  on(GetAuctionPriceListSuccess, (state, action) => {
    const auctions = state.auctions.map(
      auction => {
        const updatedAuction = action.priceList.find(auc => auc.id === auction.id);
        if(!updatedAuction) {
          return auction;
        }
        return Object.assign(new AuctionDto(), {
          ...auction,
          ...{
            actualOffer: updatedAuction.actualOffer,
            idstatoasta: updatedAuction.idstatoasta,
            isWinner: updatedAuction.isWinner,
            lastOffer: {
              ...auction.lastOffer,
              ...{
                maxValue: updatedAuction.lastOffer.maxValue
              }
            }
          }
        });
      }
    ).filter(
      auction => {
        const updatedAuction = action.priceList.find(auc => auc.id === auction.id);
        return !updatedAuction || updatedAuction.idstatoasta <= STATUS_IN_CORSO;
    });

    return {
      ...state,
      ...{
        auctions
      }
    }
  }),

  on(LoadMakesFilter, (state) => {
    return {
      ...state,
      filersMake: {
        makes: [],
        fetching: true,
        error: false
      }
    }
  }),

  on(LoadMakesFilterSuccess, (state, action) => {
    return {
      ...state,
      filersMake: {
        makes: action.makes,
        fetching: false,
        error: false
      }
    }
  }),

  on(LoadMakesFilterFailure, (state, action) => {
    return {
      ...state,
      filersMake: {
        makes: [],
        fetching: false,
        error: true
      }
    }
  })
);
